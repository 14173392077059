import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Loader from "../components/Loader";
import Frontend from "../Pages/frontend";
// import NotFound from "../Pages/NotFound";
// Admin
const Tournament = lazy(() => import("../Pages/Tournaments/Tournament"));
const TourInfo = lazy(() => import("../Pages/Tournaments/TourInfo"));
const Ranking = lazy(() => import("../Pages/Ranking/Ranking"));
const ResetPassword = lazy(() => import("../Auth/ResetPassword"));
const Verification = lazy(() => import("../Auth/Verification"));
const AdminLogin = lazy(() => import("../Auth/Admin/AdminLogin"));
const LoginHolder = lazy(() => import("../Auth/LoginHolder"));
const RegisterHolder = lazy(() => import("../Auth/RegisterHolder"));
const ForgotPassword = lazy(() => import("../Auth/ForgotPassword"));
const Shop = lazy(() => import("../Pages/Shop/Shop"));
const Smashit = lazy(() => import("../Pages/frontend/smashIt/Smashit"));

const PublicRoute = () => {
  // const isSmashit = window.location.href.includes("uat");
  return (
    <div className="wrapper">
      <div className="content">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Frontend />} />

            <Route path="smashit-uat" element={<Smashit />} />
            {/* <Route path="about-us" element={<Frontend />} /> */}
            {/* <Route path="/" element={<Frontend />} /> */}
            {/* <Route path="/" element={<NotFound />} /> */}
            <Route path="player-login" element={<LoginHolder type="player" />} />
            <Route path="academy-login" element={<LoginHolder type="academy" />} />
            <Route path="coach-login" element={<LoginHolder type="coach" />} />
            <Route path="club-member-login" element={<LoginHolder type="clubMember" />} />

            <Route path="player-register" element={<RegisterHolder type="player" />} />
            <Route path="coach-register" element={<RegisterHolder type="coach" />} />
            <Route path="club-member-register" element={<RegisterHolder type="clubMember" />} />

            {/* <Route path="player-register" element={<PlayerRegister />} /> */}

            {/* <Route path="player-login" element={<PlayerLogin />} /> */}
            {/* <Route path="academy-login" element={<AcademyLogin />} /> */}
            {/* <Route path="coach-login" element={<CoachLogin />} /> */}
            {/* <Route path="coach-register" element={<CoachRegister />} /> */}
            <Route path="player-forgot-password" element={<ForgotPassword />} />
            <Route path="coach-forgot-password" element={<ForgotPassword />} />
            <Route path="academy-forgot-password" element={<ForgotPassword />} />
            <Route path="player-reset-password/:token" element={<ResetPassword />} />
            <Route path="coach-reset-password/:token" element={<ResetPassword />} />
            <Route path="academy-reset-password/:token" element={<ResetPassword />} />
            <Route path="member-reset-password/:token" element={<ResetPassword />} />
            <Route path="admin-login" element={<AdminLogin />} />
            {/* <Route path="club-member-login" element={<ClubMemberLogin />} /> */}
            {/* <Route path="club-member-register" element={<ClubMemberRegister />} /> */}
            <Route path="rankings" element={<Ranking />} />
            <Route path="tournament-calendar" element={<Tournament />} />
            <Route path="tournaments/:tourId" element={<TourInfo />} />
            <Route path="verification" element={<Verification />} />
            <Route path="shop" element={<Shop />} />
            <Route path="*" element={<Frontend />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default PublicRoute;
