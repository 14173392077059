import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { HiOutlineMenuAlt3, HiOutlineX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../../redux/logoutSlice";
import { notify } from "../../../../components/Notification";
import { clearState } from "../../../../redux/loginSlice";
import { NavHashLink } from "react-router-hash-link";
// import { useLocation } from "react-router-dom";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const location = useLocation();

  const frontend = useSelector(state => state?.frontend?.customizationData);
  const loginState = useSelector(state => state.login);

  useEffect(() => {
    // if (location.pathname === "/smashit-uat") {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // } else {
    //   setIsScrolled(true);
    // }
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    dispatch(logoutUser());

    window.localStorage.clear();
    notify("Logout successfully", "error");
    if (loginState.user_type === "player") {
      navigate("/player-login");
    } else if (loginState.user_type === "coach") {
      navigate("/coach-login");
    } else if (loginState.user_type === "academy") {
      navigate("/academy-login");
    }
    dispatch(clearState());
  };

  return (
    <>
      <Navbar className={`${isScrolled ? "scrolled custom" : ""} nav-bar z-10`} fixed="top" data-bs-theme={isScrolled ? "light" : "dark"}>
        <Container>
          <Navbar.Brand href="/#home">
            <img alt="logo" height={50} src={frontend?.logo} />
          </Navbar.Brand>
          <Nav className="desktopView ms-auto">
            <NavHashLink className="nav-link" to="/#home">
              Home
            </NavHashLink>
            <NavHashLink className="nav-link" to="/#about-us">
              About Us
            </NavHashLink>
            <NavHashLink className="nav-link" to="/#gallery">
              Gallery
            </NavHashLink>
            <NavHashLink className="nav-link" to="/#coaches">
              Our Coaches
            </NavHashLink>
          </Nav>
          <Nav className="desktopView ms-auto">
            {loginState.access_token !== "" ? (
              <>
                <Nav.Link href="/my-profile">My Account</Nav.Link>
                <Nav.Link href="/" onClick={logout}>
                  Logout
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/player-register">
                  Register
                </Nav.Link>
                <Nav.Link as={Link} to="/player-login">
                  Login
                </Nav.Link>
              </>
            )}
          </Nav>
          <div
            className={`h3 togglenav mb-0  items-center justify-center rounded-lg bg-white p-2 text-black`}
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}>
            <HiOutlineMenuAlt3 />
          </div>
        </Container>
      </Navbar>

      {/* Custom Sidebar */}
      <div className={`custom-sidebar ${isOpen ? "open" : ""}`}>
        <button className="close-btn h1" onClick={toggleSidebar}>
          <HiOutlineX />
        </button>
        <Nav className="flex-column headingFont max-h-[100vh] flex-nowrap items-center justify-center gap-4 overflow-auto">
          <NavHashLink className="nav-link" to="/#home" onClick={toggleSidebar}>
            Home
          </NavHashLink>
          <NavHashLink className="nav-link" to="/#about-us" onClick={toggleSidebar}>
            About Us
          </NavHashLink>
          {/* <Nav.Link href="/court-of-opportunities" onClick={toggleSidebar}>
            Court Of Opportunities
          </Nav.Link> */}
          <NavHashLink className="nav-link" to="/#gallery" onClick={toggleSidebar}>
            Gallery
          </NavHashLink>
          <NavHashLink className="nav-link" to="/#coaches" onClick={toggleSidebar}>
            Our Coaches
          </NavHashLink>
          {loginState.access_token !== "" ? (
            <>
              <Nav.Link href="/my-profile" onClick={toggleSidebar}>
                My Account
              </Nav.Link>
              <Nav.Link href="/" onClick={logout}>
                Logout
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link href="/player-register" onClick={toggleSidebar}>
                Register
              </Nav.Link>
              <Nav.Link href="/player-login" onClick={toggleSidebar}>
                Login
              </Nav.Link>
            </>
          )}
        </Nav>
      </div>

      {/* Backdrop to close the sidebar */}
      {isOpen && <div className="backdrop" onClick={toggleSidebar}></div>}
    </>
  );
}
