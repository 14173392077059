import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { HiArrowUp } from "react-icons/hi";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image";

const Footer = () => {
  return (
    <footer className="bg-black-900  py-8 text-white">
      <div className="footerBg"></div>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center md:flex-row">
          {/* Follow Us Section */}
          <div className="flex space-x-4">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/smashittennisbkk" className="text-gray-400 hover:text-white">
              <FaFacebookF className="text-2xl" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/smashittennisbkk/" className="text-gray-400 hover:text-white">
              <FaInstagram className="text-2xl" />
            </a>
            {/* <a href="https://twitter.com" className="text-gray-400 hover:text-white">
              <FaTwitter className="text-2xl" />
            </a>
            <a href="https://rss.com" className="text-gray-400 hover:text-white">
              <FaRss className="text-2xl" />
            </a> */}
          </div>

          {/* Back to Top */}
          <a href="#top" className="fixed bottom-10 right-10 flex items-center  text-gray-400 hover:text-white">
            <HiArrowUp className="mr-2 text-2xl" />
            Back to Top
          </a>
        </div>

        {/* Sitemap, Feedback, Contact Us */}
        <div className="mt-6 text-sm">
          <div className="flex items-center justify-center space-x-8">
            <Link href="/sitemap" className="text-gray-400 hover:text-white">
              Sitemap
            </Link>
            <a href="mailto:smash.it.tennis.academy1@gmail.com?subject=Smash It Website Feedback" className="text-gray-400 hover:text-white">
              Feedback
            </a>
            <Link href="/contact-us" className="text-gray-400 hover:text-blue-400">
              Contact Us
            </Link>
          </div>
          <hr />
          <div className="mt-4 text-center text-gray-500 md:mt-0">
            <p>© {new Date().getFullYear()} The Smash It Tennis Academy. All rights reserved.</p>
            <p>All registered trademarks belong to their respective owners.</p>
            <p>
              Powered by{" "}
              <a href="https://www.ewizpro.com/" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-white">
                EwizPro
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
