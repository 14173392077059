import React, { useRef, useEffect, useState } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
  MdClose,
} from "react-icons/md";
import Slider from "react-slick";

export default function WhiteBox({ imgs, onClose, photo_Index }) {
  const sliderRef = useRef(null); // Reference to the Slider component
  const [, setCurrentIndex] = useState(photo_Index);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (current) => setCurrentIndex(current), // Update the current index after slide change
  };

  // Set the initial slide to photo_Index
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(photo_Index);
    }
  }, [photo_Index]);

  // Function to handle keyboard navigation
  const handleKeyDown = (event) => {
    if (!sliderRef.current) return;

    switch (event.key) {
      case "ArrowLeft":
        sliderRef.current.slickPrev();
        break;
      case "ArrowRight":
        sliderRef.current.slickNext();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Add event listener for keydown when component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className="fixed top-0 left-0 overflow-hidden h-100 w-100 bg-black bg-opacity-[30] z-[10000] p-3 p-md-5">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="fixed top-4 right-4 flex items-center justify-center h-[40px] w-[40px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full z-[10001]"
        >
          <MdClose size={20} />
        </button>

        <div>
          <button
            onClick={() => sliderRef.current.slickPrev()}
            className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1 absolute z-[10001] top-[50%] left-0"
          >
            <MdOutlineArrowBackIosNew />
          </button>
          <button
            onClick={() => sliderRef.current.slickNext()}
            className="inline-block h-[50px] w-[50px] bg-white hover:scale-110 border-none transition-all hover:bg-slate-800 hover:text-white rounded-full m-2 pb-1 absolute z-[10001] top-[50%] right-0"
          >
            <MdOutlineArrowForwardIos />
          </button>
        </div>
        <Slider {...settings} ref={sliderRef}>
          {imgs.map((thumb, index) => (
            <div
              key={index}
              className="flex items-center h-[100vh] justify-center"
            >
              <img
                className="h-100 w-auto mx-auto"
                src={thumb}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <style jsx="true">{`
        body {
          overflow: hidden;
        }
      `}</style>
    </>
  );
}
