import React, { useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const OurHappyPlayers = () => {
  const bgWaveRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = event => {
      const rect = bgWaveRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left; // Mouse X position within the section
      const y = event.clientY - rect.top; // Mouse Y position within the section

      // Calculate the offset for each img_frame based on the mouse position
      const offsetX = (x / rect.width) * 20 - 10; // Adjust 20 for sensitivity
      const offsetY = (y / rect.height) * 20 - 10;

      document.querySelectorAll(".img_frame").forEach((image, index) => {
        // Apply a smaller offset to create a slow parallax effect
        const slowFactor = 0.3 + index; // Different factor per image for variation
        image.style.transform = `translate(${offsetX * slowFactor}px, ${offsetY * slowFactor}px)`;
      });
    };

    const bgWaveElement = bgWaveRef.current;
    bgWaveElement.addEventListener("mousemove", handleMouseMove);

    return () => {
      bgWaveElement.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <section className="bgWave bg-[#F4F9FF] py-[100px]" ref={bgWaveRef} data-aos="fade-right" data-aos-offset="300">
      <Container>
        <Row>
          <Col lg={4} className="relative h-[400px]">
            <img src="./assets/images/smashit/image-1.png" className="img_frame img_frame_1 h-[120px] w-[120px]" />
            <img src="./assets/images/smashit/image-2.png" className="img_frame img_frame_2 h-[200px] w-[200px]" />
            <img src="./assets/images/smashit/image-3.png" className="img_frame img_frame_3 h-[150px] w-[150px]" />
            <img src="./assets/images/smashit/image-4.png" className="img_frame img_frame_4 h-[100px] w-[100px]" />
          </Col>
          <Col lg={8}>
            <ScrollAnimation animateIn="fadeInDown">
              <div className="mb-8">
                <h6 className="text-white">Smash It Tennis Academy</h6>
                <h2 className="headingFont mb-4 font-bold text-white">Our Happy Players</h2>
              </div>
            </ScrollAnimation>
            <b className="mb-3 block text-white">Achieve Your Best Self</b>
            <p className="mb-3 text-white">Stand Out in the Competitive Tennis World</p>
            <p className="mb-3 text-white">
              With the competition growing fiercer, it's essential to set yourself apart from other athletes. While skills, dedication, and training
              are key, showcasing your unique strengths—especially in tennis—can make all the difference. Tennis, known as the "sport of a lifetime,"
              holds immense potential for personal growth and recognition. Discover how your passion for tennis can unlock new opportunities and
              elevate your game.
            </p>
          </Col>
        </Row>
      </Container>
      <style>
        {`
          .img_frame {
            transition: transform 0.1s ease;
          }
        `}
      </style>
    </section>
  );
};

export default OurHappyPlayers;
