// src/pages/Smashit.js
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import ImageSlider from "./ImageSlider";
import ProgramSection from "./ProgramSection";
// import Testimonials from "./Testimonials";
import CoachList from "./CoachList";
// import PlayerList from "./PlayerList";
import Gallery from "./Gallery";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import SocialBar from "../../../public/scss/components/SocialBar";
// import PlayerList from "./PlayerList";
import OurHappyPlayers from "./OurHappyPlayers";

const Smashit = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <main className="smash-home">
      <Header />
      <ImageSlider />
      <ProgramSection />
      <OurHappyPlayers />
      {/* <PlayerList /> */}
      <Gallery />
      <CoachList />
      <Footer />
      <SocialBar variant={"variant1"} />
    </main>
  );
};

export default Smashit;
