import store from "store";

const localStorage = {
  /**
   * Set value by key paire into localStorage
   */
  setValue: function (key, value) {
    store.set(key, value);
  },
  /**
   * Get value from localStorage
   */
  getValue: function (key) {
    return store.get(key);
  },
  /**
   * Clear all localStorage
   */
  cleanStore: function () {
    store.clearAll();
  },
  /**
   * Get access token form storage
   */
  //  getAccessToken: function () {
  // 	return store.get(storKey.ACCESS_TOKEN);
  // },
};

export default localStorage;
