import React from "react";
import { Container } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "../../../components/Image";

export default function ProgramSection() {
  return (
    <section className="portfolio-section relative py-[50px] md:py-[100px]" data-aos="fade-right" data-aos-offset="500" id="about-us">
      <Container>
        <div className="row align-items-center mb-[100px] mt-0 md:mt-[50px]">
          <div className="col-lg-6 heading">
            <ScrollAnimation animateIn="fadeInDown">
              <h6 className="text-theme ">Smash It Tennis Academy Story</h6>
              <h2 className="headingFont mb-4 font-bold">The Journey Behind Smash It Tennis Academy: A Legacy of Excellence</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInDown" delay={500}>
              <p className="mt-4 text-justify">
                Founded with a passion for excellence in the heart of Thailand, Smash It Tennis Academy was established by a team of dedicated tennis
                professionals with a shared vision: to provide world-class training for players of all abilities and ages. Our founders, who have
                years of experience both on the court and in coaching, sought to create an academy that fosters a love for the game while developing
                high-performance athletes.
              </p>
              <p className="mt-4 text-justify">
                The purpose of Smash It Tennis Academy is simple: to nurture talent, instill discipline, and offer unparalleled coaching that empowers
                players to reach their full potential. Whether you are a young beginner discovering the sport for the first time or an advanced player
                aiming to refine your skills for competition, we are here to support your journey every step of the way.
              </p>
              <p className="mt-4 text-justify">
                Our vision is to be the premier destination for tennis training in Asia, where players can not only hone their technical abilities but
                also develop the mental toughness, strategy, and sportsmanship needed to excel on and off the court. With a holistic approach to
                training and a team of expert coaches, Smash It Tennis Academy is committed to shaping the champions of tomorrow.
              </p>

              {/* <Nav.Link className="text-main mt-4 uppercase hover:underline hover:underline-offset-1" href="/court-of-opportunities">            <img src="./assets/images/smashit/image-4.png" className={"w-[100px] h-[100px] img_frame img_frame_4"} />

                Read More
              </Nav.Link> */}

              <div className="row g-3 mt-5">
                {/* Image Grid */}
                {Array(5)
                  .fill(null)
                  .map((_, index) => {
                    return (
                      <div className="col-sm-2 col-6" key={index}>
                        <ScrollAnimation animateIn="fadeInDown" delay={index + 1 * 200}>
                          <div className="program_card">
                            <img
                              src={`./assets/images/smashit/Gallery/camp-${index + 1}.jpg`}
                              alt="About Smash It Academy"
                              className=" h-[100px] rounded object-cover shadow-sm"
                            />
                            {/* <div className="sectionInfo">
                              <h5>Performance Player Development Coaching Workshop</h5>
                              <p>at the Smash It Tennis Academy</p>
                            </div> */}
                          </div>
                        </ScrollAnimation>
                      </div>
                    );
                  })}
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </Container>
      <div className="sectionStoryShaps">
        <Image src={"./assets/images/smashit/image 9.png"} className={"mainChar"} />
      </div>
    </section>
  );
}
